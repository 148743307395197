import React from "react";
import { Flex } from "../../style/Grid";
import { Text } from "../../style/Text";
import {
  AdvantageBlockWrapper,
  AdvantageBlockItem,
  AdvantageBlockTop,
  AdvantageBlockTopHeading,
} from "../../style/AdvantageBlock";
import AdvantageBlockBottom from "./AdvantageBlockBottom";
import Icon from "../../style/Icon";

const HowCanWeHelp = ({ advantages, title, bottomContent }) => (
  <AdvantageBlockWrapper mb={5}>
    {advantages && (
      <AdvantageBlockTop px={[3, null, 4, 45]} pt={4}>
        <AdvantageBlockTopHeading uppercase>{title}</AdvantageBlockTopHeading>
        <Flex flexWrap={"wrap"} justifyContent={"space-between"}>
          {advantages.map((advantage, index) => (
            <AdvantageBlockItem
              width={[1, null, "calc(50% - 10px)"]}
              key={index}
            >
              <Icon icon="icon-check" color="cta" size={17} mt={2} />
              <Text
                color="whyAgent.text"
                fontSize={["15px", null, 2]}
                ml={[10, null, null, 27]}
                width={"90%"}
              >
                {advantage.advantage}
              </Text>
            </AdvantageBlockItem>
          ))}
        </Flex>
      </AdvantageBlockTop>
    )}

    <AdvantageBlockBottom bottomContent={bottomContent} />
  </AdvantageBlockWrapper>
);

export default HowCanWeHelp;
