import styled from "styled-components";
import { Flex } from "../Grid";

const PartnersBlockWrapper = styled(Flex)``;

PartnersBlockWrapper.displayName = "PartnersBlockWrapper";

PartnersBlockWrapper.defaultProps = {
  flexDirection: "column",
  alignItems: "center",
  mt: [5, null, null, 0],
  mb: 90,
  width: 1,
};

export { PartnersBlockWrapper };
