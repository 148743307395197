import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/Layout";
import Header from "../../components/Header";
import { Hero } from "../../components/HeroV2";
import HowCanWeHelp from "../../components/AdvantageBlock/HowCanWeHelp";
import PartnersBlock from "../../components/PartnersBlock";
import ProductsBlock from "../../components/ProductsBlock";
import { Button } from "../../style/Button";
import Link from "../../components/Link";
import SEO from "../../components/seo";

import { Container, Flex, Wrapper } from "../../style/Grid";
import { Heading } from "../../style/Heading";

import { URLS } from "../../const/const";
import productOrderConfig from "../../config/productOrderConfig";
import MagazineBlock from "../../components/MagazineBlock";
import StoryBlockSlider from "../../components/StoryBlock/StoryBlockSlider";
import { randomizeArray } from "../../utils";

const SituationDetailDefaultTemplate = ({
  data: {
    wp: {
      pageBy: {
        id,
        slug,
        title,
        featuredImage,
        categories: { nodes: categories },
        pageContent: {
          banner,
          selectedStoriesGeneral: stories,
          howcanwehelp: advantages,
          partners,
        },
        seo: { metaDesc },
      },
    },
  },
}) => {
  const {
    products: { nodes: products },
    posts: { nodes: posts },
  } = categories[0];
  const breadcrumbs = [
    {
      label: "S čím pomůžeme",
      link: URLS.situations,
    },
    {
      label: title,
      link: slug,
    },
  ];

  const sortedProducts = productOrderConfig(id, products);

  return (
    <Layout>
      <SEO title={title} description={metaDesc} />
      <Header variant="light" activeNav={URLS.situations} />

      <Hero
        breadcrumbs={breadcrumbs}
        image={featuredImage.node}
        banner={{
          ...banner,
          cta: (
            <Button
              as={Link}
              href={`${URLS.contactForm}?type=${slug}`}
              display="inline-block"
              mt={15}
            >
              Mám zájem
            </Button>
          ),
        }}
      />

      <Wrapper>
        <Container>
          <ProductsBlock products={sortedProducts} withHeading={false} />
        </Container>
        <Container>
          <HowCanWeHelp advantages={advantages} title={"Jak vám pomůžeme?"} />
        </Container>
      </Wrapper>

      {stories && (
        <>
          <Wrapper>
            <Container flexDirection="row" justifyContent={"space-between"}>
              <Flex
                width={[1, null, null, "70%"]}
                maxWidth={["600px", null, null, "70%"]}
                pb={[0, null, null, 0]}
              >
                <Heading
                  as="h2"
                  mb={[2, null, 4]}
                  fontSize={[4, null, 5]}
                  lineHeight={0}
                  color="headlines"
                  fontWeight="light"
                  letterSpacing="caps"
                >
                  JAK JSME POMOHLI NAŠIM KLIENTŮM
                </Heading>
              </Flex>
            </Container>
          </Wrapper>
          <StoryBlockSlider
            stories={randomizeArray(stories)}
            mb={4}
            showProducts
            showLeftPane
            categoryTitle={title}
            categorySlug={slug}
            hideLink
          />
        </>
      )}

      {posts?.length !== 0 && (
        <Wrapper mt={[5, null, null, 0]}>
          <Container>
            <MagazineBlock
              posts={posts}
              categoryTitle={categories[0].name}
              categorySlug={categories[0].slug}
              variant={"light"}
              mb={[0, null, null, 5]}
            />
          </Container>
        </Wrapper>
      )}

      {partners && (
        <Wrapper>
          <Container justifyContent={"center"}>
            <PartnersBlock partners={partners} />
          </Container>
        </Wrapper>
      )}
    </Layout>
  );
};
export default SituationDetailDefaultTemplate;

export const pageQuery = graphql`
  query SITUATION_DETAIL($uri: String) {
    wp {
      pageBy(uri: $uri) {
        id
        slug
        title
        featuredImage {
          node {
            sourceUrl(size: MEDIUM_LARGE)
          }
        }
        categories {
          nodes {
            id
            name
            slug
            products {
              nodes {
                id
                title
                slug
                uri
                pageContent {
                  template
                }
                productMedailon {
                  description
                  title
                  icon {
                    sourceUrl
                  }
                }
              }
            }
            posts(first: 4) {
              nodes {
                ...PostInformation
              }
            }
          }
        }
        pageContent {
          banner {
            content
            cta {
              target
              title
              url
            }
            title
            titletop
          }
          howcanwehelp {
            advantage
          }
          partners {
            partnerName
            partnerLogo {
              sourceUrl
            }
          }
          selectedStoriesGeneral {
            ...StoryInformation
          }
        }
        seo {
          metaDesc
        }
      }
    }
  }
`;
