import styled from "styled-components";
import { Flex } from "../Grid";

const PartnersBlockItem = styled(Flex)``;

PartnersBlockItem.displayName = "PartnersBlockItem";

PartnersBlockItem.defaultProps = {
  padding: "0 16px",
  flexBasis: "140px",
  alignItems: "center",
  justifyContent: "center",
  // mx: "auto",
  mb: 3,
};

export { PartnersBlockItem };
