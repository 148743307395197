const orderConfig = {
  "cGFnZTo0MTU=": [
    {
      // Investice do bydleni
      productId: "cHJvZHVjdDo4NjE=",
      order: 1,
    },
    {
      // Realitni servis
      productId: "cHJvZHVjdDo0ODU=",
      order: 2,
    },
    {
      // Hypoteky a refinancovani
      productId: "cHJvZHVjdDo0MjU=",
      order: 3,
    },
    {
      // Stavebni sporeni
      productId: "cHJvZHVjdDo0Mjc=",
      order: 4,
    },
    {
      // Pojisteni nemovitosti
      productId: "cHJvZHVjdDo0Mjk=",
      order: 5,
    },
    {
      // Energie
      productId: "cHJvZHVjdDo2OTA=",
      order: 6,
    },
  ],
  "cGFnZTo0NjI=": [
    {
      // Podílové fondy
      productId: "cHJvZHVjdDo4ODM=",
      order: 1,
    },
    {
      // Investice do dluhopisů
      productId: "cHJvZHVjdDo4ODQ=",
      order: 2,
    },
    {
      // Cenné kovy
      productId: "cHJvZHVjdDo4ODY=",
      order: 3,
    },
    {
      // Doplňkové penzijní spoření
      productId: "cHJvZHVjdDo4ODg=",
      order: 4,
    },
    {
      // Stavební spoření
      productId: "cHJvZHVjdDo0Mjc=",
      order: 5,
    },
    {
      // Nákup nemovitostí
      productId: "cHJvZHVjdDo4ODU=",
      order: 6,
    },
    {
      // Realokace prostředků
      productId: "cHJvZHVjdDo4ODk=",
      order: 7,
    },
  ],
  "cGFnZTo0ODI=": [
    {
      // Pojištění nemovitosti
      productId: "cHJvZHVjdDo0Mjk=",
      order: 1,
    },
    {
      // Pojištění aut
      productId: "cHJvZHVjdDo4NjQ=",
      order: 2,
    },
    {
      // Pojištění odpovědnosti
      productId: "cHJvZHVjdDo4NjY=",
      order: 3,
    },
    {
      // Domácí mazlíčci
      productId: "cHJvZHVjdDo4Njc=",
      order: 4,
    },
    {
      // Cestovní pojištění
      productId: "cHJvZHVjdDo4Njk=",
      order: 5,
    },
    {
      // Pojištění podnikatelů
      productId: "cHJvZHVjdDo4Njg=",
      order: 6,
    },
  ],
  "cGFnZTo0ODM=": [
    {
      // Tvorba finančních rezerv
      productId: "cHJvZHVjdDo4OTE=",
      order: 1,
    },
    {
      // Životní pojištění
      productId: "cHJvZHVjdDo4OTA=",
      order: 2,
    },
    {
      // Spokojená penze
      productId: "cHJvZHVjdDo4OTI=",
      order: 3,
    },
    {
      // Právní ochrana rodiny
      productId: "cHJvZHVjdDo4OTU=",
      order: 4,
    },
    {
      // Cestovní pojištění
      productId: "cHJvZHVjdDo4Njk=",
      order: 5,
    },
    {
      // Bankovní služby
      productId: "cHJvZHVjdDo4OTQ=",
      order: 6,
    },
    {
      // Energie
      productId: "cHJvZHVjdDo4OTM=",
      order: 7,
    },
  ],
  "cGFnZTo0ODA=": [
    {
      // Doplňkové penzijní spoření
      productId: "cHJvZHVjdDo4ODg=",
      order: 1,
    },
    {
      // Podílové fondy
      productId: "cHJvZHVjdDo4ODM=",
      order: 2,
    },
    {
      // Investice do dluhopisů
      productId: "cHJvZHVjdDo4ODQ=",
      order: 3,
    },
    {
      // Cenné kovy
      productId: "cHJvZHVjdDo4ODY=",
      order: 4,
    },
    {
      // Nákup nemovitostí
      productId: "cHJvZHVjdDo4ODU=",
      order: 5,
    },
  ],
  "cGFnZTo0MjQ=": [
    {
      // Pojištění automobilů
      productId: "cHJvZHVjdDo4NjQ=",
      order: 1,
    },
    {
      // Spotřebitelské úvěry
      productId: "cHJvZHVjdDo4NjM=",
      order: 2,
    },
    {
      // Leasing
      productId: "cHJvZHVjdDo0MjY=",
      order: 3,
    },
  ],
  "cGFnZTo0ODE=": [
    {
      // Financování a leasing na provozní činnost
      productId: "cHJvZHVjdDo5MDA=",
      order: 1,
    },
    {
      // Pojištění činností a odpovědností (z nich plynoucích)
      productId: "cHJvZHVjdDo4OTc=",
      order: 2,
    },
    {
      // Pojištění movitého a nemovitého majetku
      productId: "cHJvZHVjdDo4OTY=",
      order: 3,
    },
    {
      // Pojištění firemních statutárních orgánů a SVJ i družstev
      productId: "cHJvZHVjdDo4OTk=",
      order: 4,
    },
    {
      // Daňové úlevy pro zaměstnance i zaměstnavatele
      productId: "cHJvZHVjdDo4OTg=",
      order: 5,
    },
  ],
};

export default (parent, products) => {
  products = products.map(product => {
    const data = orderConfig[parent]?.find(
      tempProduct => tempProduct.productId === product.id
    );
    return {
      order: data?.order,
      ...product,
    };
  });
  return products.sort((a, b) => a.order - b.order);
};
