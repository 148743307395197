import React from "react";
import {
  PartnersBlockHeading,
  PartnersBlockItem,
  PartnersBlockLogoWrapper,
  PartnersBlockWrapper,
} from "../../style/PartnersBlock";
import { Image } from "../../style/Image";

const PartnersBlock = ({ partners }) => (
  <PartnersBlockWrapper>
    <PartnersBlockHeading uppercase>Partneři</PartnersBlockHeading>

    <PartnersBlockLogoWrapper>
      {partners.map(
        (partner, index) =>
          partner && (
            <PartnersBlockItem key={index}>
              <Image
                width={"100%"}
                height={"auto"}
                src={partner?.partnerLogo?.sourceUrl}
                alt={partner?.partnerName}
              />
            </PartnersBlockItem>
          )
      )}
    </PartnersBlockLogoWrapper>
  </PartnersBlockWrapper>
);

export default PartnersBlock;
