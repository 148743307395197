import styled from "styled-components";
import { Heading } from "../Heading";

const PartnersBlockHeading = styled(Heading)``;

PartnersBlockHeading.displayName = "PartnersBlockHeading";

PartnersBlockHeading.defaultProps = {
  as: "h2",
  lineHeight: "38px",
  fontWeight: 300,
  textAlign: "center",
  mb: 4,
};

export { PartnersBlockHeading };
