import styled from "styled-components";
import { Flex } from "../Grid";

const PartnersBlockLogoWrapper = styled(Flex)``;

PartnersBlockLogoWrapper.displayName = "PartnersBlockLogoWrapper";

PartnersBlockLogoWrapper.defaultProps = {
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "center",
  width: 1,
};

export { PartnersBlockLogoWrapper };
